'use client';

import {
  ForwardedRef,
  RefCallback,
  RefObject,
  useCallback,
  useRef,
} from 'react';

export const useSharedForwardRef = <T>(
  ref: ForwardedRef<T>,
): [RefObject<T>, RefCallback<T>] => {
  const localRef = useRef<T | null>(null);

  const setRef = useCallback<RefCallback<T>>((node) => {
    localRef.current = node;

    if (typeof ref === 'function') {
      ref(node);
    } else if (ref) {
      ref.current = node;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [localRef, setRef];
};
