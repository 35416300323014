import { MDSText, ValidationProps } from '@midwest/web/base';
import styled from 'styled-components';

export const PrefixInputContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
`;

export const TextInputStyles = styled.input<ValidationProps>`
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  align-self: stretch;
  border: none;
  background-color: transparent;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.45;
  color: ${(p) => p.theme.midwestColors.textSecondary};
  height: 48px;

  &::placeholder {
    font-family: ${MDSText.BodyHeavySm['font']};
    font-weight: ${MDSText.BodyHeavySm['weight']};
    line-height: ${MDSText.BodyHeavySm['lineHeight']};
    color: ${(p) =>
      p.$disabled
        ? p.theme.midwestColors.textDisabled
        : p.$readOnly
          ? 'transparent'
          : p.theme.midwestColors.componentFormColorTextPlaceholder};
  }
  &:hover,
  &:focus {
    outline: transparent;
  }

  padding-block: 0;
  padding-inline: 0;

  cursor: ${(p) =>
    p.$disabled ? 'not-allowed' : p.$readOnly ? 'text' : 'auto'};

  @media not (prefers-reduced-motion) {
    transition: background-color 5000s ease-in-out 0s;
  }
  background-clip: text;
`;

export const ErrorTextStyles = styled(MDSText.BodyMediumSm)`
  color: ${(p) => p.theme.midwestColors.sentimentNegativeStrong};
`;

export const AppendPrependLabel = styled(MDSText.BodyRegSm)`
  border: 1px solid ${(p) => p.theme.midwestColors.borderDefault};
  border-radius: ${(p) => p.theme.radius.radiusSmall};
  background: ${(p) => p.theme.midwestColors.backgroundSecondary};
  padding: 0 0.25rem;
  color: ${(p) => p.theme.midwestColors.textSecondary};
  align-self: center;
`;

export const IconContainer = styled.div<{ appended: boolean }>`
  margin-left: ${(p) => (p.appended ? '0' : 'auto')};
  display: flex;
  align-items: center;
`;
