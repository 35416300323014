'use client';

import { MDSText } from '@exploration/brandRefreshTheme';
import {
  Container as ExplorationContainer,
  Section as ExplorationSection,
} from '@exploration/shared-components';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const Section = styled(ExplorationSection)`
  display: flex;
  align-items: center;
  padding: 2rem 0 2rem;
  background: ${(p) => p.theme.midwestColors.identityBrandPrimaryFaint};
`;

export const Container = styled(ExplorationContainer)``;

export const List = styled.ul`
  padding-left: 0.75rem;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
`;

export const ListItem = styled(MDSText.BodyRegDefault)`
  line-height: 1rem;
  color: ${(p) => p.theme.midwestColors.neutralDarkMedium};
  margin-top: 1rem;
  font-size: 0.75rem;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const ComplianceId = styled(MDSText.BodyRegDefault)`
  text-align: right;
  margin-top: 1rem;
  display: block;
  color: ${(p) => p.theme.midwestColors.neutralDarkMedium};
  font-size: 0.75rem;
`;
