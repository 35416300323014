import { MDSText, ValidationProps } from '@midwest/web/base';
import { MDSLink } from '@midwest/web/link';
import styled from 'styled-components';

const LabelAndLinkContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 20px;
`;

const Label = styled(MDSText.BodyHeavySm)<ValidationProps>`
  color: ${(p) =>
    p.$disabled
      ? p.theme.midwestColors.textDisabled
      : p.$readOnly
        ? p.theme.midwestColors.textSecondary
        : p.$focused
          ? p.theme.midwestColors.textLink
          : p.theme.midwestColors.textSecondary};
`;

const ValidationText = styled(MDSText.BodyRegSm)`
  font-style: italic;
  color: ${(p) => p.theme.midwestColors.textSecondary};
`;

export const LabelAndLink = (props: {
  label: string;
  linkLabel?: string;
  linkHref?: string;
  disabled: boolean;
  readOnly?: boolean;
  focused: boolean;
  inputId?: string;
  labelId?: string;
  validationText?: 'required' | 'optional';
}) => {
  const {
    label,
    linkLabel,
    linkHref,
    disabled,
    readOnly,
    focused,
    inputId,
    labelId,
    validationText,
  } = props;

  return (
    <LabelAndLinkContainer>
      <Label
        id={labelId}
        htmlFor={inputId}
        as="label"
        $focused={focused}
        $disabled={disabled}
        $readOnly={readOnly}
      >
        {label}
        {validationText && <ValidationText> ({validationText})</ValidationText>}
      </Label>
      {linkLabel && linkHref ? (
        <MDSLink href={linkHref} type="default">
          <MDSText.BodyHeavySm>{linkLabel}</MDSText.BodyHeavySm>
        </MDSLink>
      ) : null}
    </LabelAndLinkContainer>
  );
};
