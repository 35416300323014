'use client';

import {
  AppendPrependLabel,
  ErrorTextStyles,
  IconContainer,
  PrefixInputContainer,
  TextInputStyles,
} from './TextInputStyles';
import { WarningIcon, useSharedForwardRef } from '@midwest/web/base';
import {
  FormComponentLayout,
  HelperText,
  InputContainer,
  InputContainerCursor,
  LabelAndLink,
  validationTextType,
} from '@midwest/web/shared';
import { colors } from '@thrivent/midwest-shared';
import {
  ChangeEventHandler,
  FocusEventHandler,
  InputHTMLAttributes,
  forwardRef,
  useId,
  useState,
} from 'react';

type TextInputProps = {
  name: string;
  value?: string;
  label: string;
  helperText?: string;
  errorText?: string;
  errorItems?: string[];
  placeholder?: string;
  linkLabel?: string;
  linkHref?: string;
  prependLabel?: string;
  appendLabel?: string;
  invalid?: boolean;
  disabled?: boolean;
  required?: boolean;
  readOnly?: boolean;
  inputType?: 'email' | 'number' | 'password' | 'text';
  onChange?: ChangeEventHandler;
  onBlur?: FocusEventHandler;
  onFocus?: FocusEventHandler;
  inputAttributes?: InputHTMLAttributes<HTMLInputElement>;
  validationText?: validationTextType;
};

/**
 ```jsx
 import { MDSTextInput } from '@midwest/web/forms';
 ```
 * A text field that users can type into. Usually free form input but designers can apply restrictions with validation.
 */
export const MDSTextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      name,
      value,
      label,
      helperText,
      errorText,
      placeholder,
      linkLabel,
      linkHref,
      prependLabel,
      appendLabel,
      invalid,
      disabled = false,
      required = false,
      readOnly = false,
      inputType,
      onChange,
      onBlur,
      onFocus,
      inputAttributes = {},
      validationText,
      ...rest
    },
    ref,
  ) => {
    const onChangePreventDefault = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      if (onChange) {
        onChange(event);
      }

      event.preventDefault();
    };

    const [focused, setFocused] = useState(false);
    const [textInputRef, setRef] = useSharedForwardRef(ref);

    /* Create state variable to detect when textarea is clicked into to differentiate focus and focus-visible styles with wrapping InputContainer component */
    const [inputClicked, setInputClicked] = useState(false);

    const inputId = useId();
    const errorIdHook = useId();
    const describedByIdHook = useId();
    const handleClick = () => {
      textInputRef?.current?.focus();
      setFocused(true);
    };

    // aria attributes throw accessibility errors if the reference doesn't exist
    const errorId = errorText ? errorIdHook : undefined;
    const describedById = helperText ? describedByIdHook : undefined;
    const ariaDescribedBy = [describedById, errorId].reduce((prev, id) => {
      if (!id) {
        return prev;
      }
      return prev ? `${prev} ${id}` : `${id}`;
    }, undefined);

    const renderIcon = () => {
      if (invalid === undefined || disabled) return null;
      const iconHeightWidth = {
        height: 18,
        width: 18,
      };
      return (
        <IconContainer appended={!!appendLabel}>
          <WarningIcon
            {...iconHeightWidth}
            fill={colors.sentimentNegativeStrong}
            title="warning-icon"
          />
        </IconContainer>
      );
    };

    return (
      <FormComponentLayout {...rest}>
        <LabelAndLink
          label={label}
          linkLabel={linkLabel}
          linkHref={linkHref}
          disabled={disabled}
          readOnly={readOnly}
          focused={focused}
          inputId={inputId}
          validationText={validationText}
        />
        {!!helperText && (
          <HelperText id={describedById}>{helperText}</HelperText>
        )}
        <InputContainerCursor $disabled={disabled} $readOnly={readOnly}>
          <InputContainer
            onFocus={handleClick}
            onBlur={() => {
              setFocused(false);
              setInputClicked(false);
            }}
            $focused={focused}
            $invalid={invalid}
            $disabled={disabled}
            $readOnly={readOnly}
            $inputClicked={inputClicked}
            onMouseDown={() => {
              setInputClicked(true);
              handleClick();
            }}
          >
            <PrefixInputContainer>
              {!!prependLabel && (
                <AppendPrependLabel>{prependLabel}</AppendPrependLabel>
              )}
              <TextInputStyles
                {...inputAttributes}
                $focused={focused}
                name={name}
                value={value}
                onChange={onChangePreventDefault}
                onBlur={onBlur}
                onFocus={onFocus}
                ref={setRef}
                placeholder={placeholder}
                aria-required={required}
                readOnly={readOnly}
                $readOnly={readOnly}
                aria-readonly={readOnly}
                $disabled={disabled}
                disabled={disabled}
                type={inputType}
                id={inputId}
                aria-invalid={!!invalid}
                aria-errormessage={errorId}
                aria-describedby={ariaDescribedBy}
                data-dd-action-name={label}
              />

              {!!appendLabel && (
                <AppendPrependLabel>{appendLabel}</AppendPrependLabel>
              )}
              {invalid && renderIcon()}
            </PrefixInputContainer>
          </InputContainer>
        </InputContainerCursor>

        {!!errorText && (
          <ErrorTextStyles id={errorId} role={'status'}>
            {errorText}
          </ErrorTextStyles>
        )}
      </FormComponentLayout>
    );
  },
);

MDSTextInput.displayName = 'MDSTextInput';
