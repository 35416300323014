'use client';

import {
  ConnectSectionButton,
  ConnectSectionDescription,
  ConnectSectionInput,
  ConnectSectionInputButtonRow,
  ConnectSectionTitle,
  Container,
  Section,
} from './connect-section.style';
import { useRouter } from 'next/navigation';
import { useState } from 'react';

// --------------------------------------------------------------------------

// This ConnectSection is for the 2025 Brand Refresh. It has hardcoded styling for the new colors
// and fonts that we are using. This note is here to identify this until we don't have multiple versions.

// --------------------------------------------------------------------------

export const ConnectSection = ({
  title,
  bodyCopy,
}: {
  title: string;
  bodyCopy: string;
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const router = useRouter();

  const goToAdvisorSearch = (url = '') => {
    let value = '';
    if (inputValue) {
      value = `?qp=${inputValue}`;
    }
    router.push(`${url}${value}`);
  };

  // Made this a const to avoid lint issue down below
  const RightArrow = '->';

  return (
    <Section>
      <Container>
        <ConnectSectionTitle as="h2">{title}</ConnectSectionTitle>
        <ConnectSectionDescription as="p">{bodyCopy}</ConnectSectionDescription>
        <ConnectSectionInputButtonRow>
          <ConnectSectionInput
            name="search-input"
            label="Search by location"
            placeholder="Zip or City, State"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setInputValue(e.currentTarget.value)
            }
          />
          <ConnectSectionButton
            role="link"
            href="https://local.thrivent.com"
            onClick={() => goToAdvisorSearch('https://local.thrivent.com')}
            variant="secondary"
            data-analytics-id="connect-section-cta-button"
            data-dd-action-name={'Connect Section Find an Advisor Search CTA'}
          >
            Continue {RightArrow}
          </ConnectSectionButton>
        </ConnectSectionInputButtonRow>
      </Container>
    </Section>
  );
};
