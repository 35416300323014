import { MDSText } from '@exploration/brandRefreshTheme';
import {
  Container as ExplorationContainer,
  Section as ExplorationSection,
} from '@exploration/shared-components';
import { MDSAnchorButton } from '@midwest/web/button';
import { MDSTextInput } from '@midwest/web/forms';
import { InputContainer } from '@midwest/web/shared';
import { tablet } from '@thrivent/midwest-shared';
import styled from 'styled-components';

// --------------------------------------------------------------------------

export const Section = styled(ExplorationSection)`
  background: ${(p) => p.theme.midwestColors.identityBrandPrimaryHeavy};
  color: ${(p) => p.theme.midwestColors.white};
  padding: 3.75rem 0;

  ${tablet} {
    padding: 6.25rem 0;
  }
`;

export const Container = styled(ExplorationContainer)``;

export const ConnectSectionTitle = styled(MDSText.HeroLg)`
  font-weight: 700;
  text-align: center;
`;

export const ConnectSectionDescription = styled(MDSText.Subtitle)`
  text-align: center;
  margin-top: 1rem;
`;

export const ConnectSectionInputButtonRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;

  ${tablet} {
    flex-direction: row;
  }
`;

export const ConnectSectionButton = styled(MDSAnchorButton)`
  margin-top: 1rem;
  width: 100%;
  max-width: 24rem;

  &:focus-visible::after {
    border: 3px solid ${(p) => p.theme.midwestColors.borderFocusDarkBg};
  }

  ${tablet} {
    margin-top: 0rem;
    max-width: 8.75rem;
  }
`;

export const ConnectSectionInput = styled(MDSTextInput)`
  width: 100%;
  max-width: 24rem;
  gap: 0;
  padding-bottom: 0;

  ${InputContainer}:focus-within {
    outline: 4px solid ${(p) => p.theme.midwestColors.borderFocusDarkBg};
  }

  > div:first-of-type {
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }

  ${tablet} {
    padding-right: 1rem;
  }
`;
