import { ValidationProps } from '@midwest/web/base';
import styled, { CSSObject } from 'styled-components';

interface InputContainerProps extends ValidationProps {
  $disabled?: boolean;
  $invalid?: boolean;
  $focused?: boolean;
  $inputClicked?: boolean;
  $additionalStyles?: CSSObject;
  $readOnly?: boolean;
}

export const InputContainerCursorPointer = styled.div<InputContainerProps>`
  cursor: ${(p) => {
    if (p.$disabled) return 'not-allowed';
    if (p.$readOnly) return 'text';
    return 'pointer';
  }};
  width: 100%;
`;

export const InputContainerCursor = styled.div<InputContainerProps>`
  cursor: ${(p) => (p.$disabled ? 'not-allowed' : 'auto')};
  width: 100%;
`;

export const InputContainer = styled.div<InputContainerProps>`
  border-radius: 5px;
  border: 2px solid
    ${(p) =>
      p.$disabled
        ? p.theme.midwestColors.borderDefault
        : p.$readOnly
          ? `none`
          : p.$focused
            ? p.theme.midwestColors.textLink
            : p.$invalid
              ? p.theme.midwestColors.sentimentNegativeStrong
              : p.theme.midwestColors.neutralLightHeavy};
  background: ${(p) =>
    p.$disabled
      ? p.theme.midwestColors.neutralLightMedium
      : p.$readOnly
        ? p.theme.midwestColors.textSecondaryDarkBG
        : p.theme.midwestColors.backgroundPrimary};
  margin: 0;
  display: flex;
  gap: 12px;
  flex: 1 0 0;
  width: 100%;
  pointer-events: ${(p) => (p.$disabled ? 'none' : '')};
  align-items: center;
  box-sizing: border-box;
  height: 48px;

  padding: ${(p) => (p.$disabled ? '12px' : p.$readOnly ? '14px' : '12px')};

  &:hover,
  &:focus {
    border: ${(p) =>
      p.$disabled
        ? `${p.theme.border.widthEmphasised} solid ${p.theme.midwestColors.neutralDarkStrong}`
        : p.$readOnly
          ? `none`
          : `${p.theme.border.widthEmphasised} solid ${p.theme.midwestColors.neutralDarkStrong}`};
  }

  &:focus-within {
    border: 2px solid
      ${(p) =>
        p.$disabled
          ? p.theme.midwestColors.neutralLightHeavy
          : p.$readOnly
            ? 'none'
            : p.$focused
              ? p.theme.midwestColors.textLink
              : p.theme.midwestColors.neutralLightHeavy};
    outline: none;
    ${(p) =>
      p.$inputClicked === false &&
      `outline: 4px solid ${p.theme.midwestColors.identityBrandPrimaryHeavy};
      border: ${
        p.$disabled
          ? `2px solid ${p.theme.midwestColors.textLink}`
          : p.$readOnly
            ? 'none'
            : p.$invalid
              ? `2px solid ${p.theme.midwestColors.sentimentNegativeStrong}`
              : `2px solid ${p.theme.midwestColors.textLink}`
      };`}
  }

  ${(p) => p.$additionalStyles};
`;
